import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import {
  Col,
  Container,
  Row,
  Spinner,
  Image,
  ListGroup,
  FormControl,
  ListGroupItem,
} from "react-bootstrap";
import {
  IoChevronBack,
  IoBagCheck,
  IoLogOut,
  IoWallet,
  IoFlameSharp,
  IoHammerSharp,
} from "react-icons/io5";
import DistributionTable from "./DistributionTable";

import { connect, WalletConnection, utils, Contract } from "near-api-js";
import { getConfig } from "../../config";

import {
  initContract,
  login,
  logout,
  fetchMetadata,
  fetchMinInvestment,
  fetchTotalSupply,
  fetchTokenAllocation,
  fetchAccountBalance,
  buyToken,
  callBof,
  updateBaseprice,
  burnToken,
  initRefContract,
  callftTransfer,
  calculateInvestment,
  calculateTotalInvestment,
  callftTransferCall,
  calculateReturn,
  getTransactionResult,
  fetchRefConMetadata,
} from "../../abi/near/utils";

import SuccessModal from "../common/SuccessModal";
import ErrorModal from "../common/ErrorModal";

import makerLogo from "../../assets/images/indexLogos/AlphaGen.png";
import nearLogo from "../../assets/images/near-protocol.svg";
import necoPdf from "../../assets/pdfs/NECO.pdf";

/* A custom hook that builds on useLocation to parse
 the query string for you. */
function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const capitalizeFirstLetter = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

const {
  format: { formatNearAmount },
} = utils;

const PortfolioPage = (props) => {
  const { chn, id } = useParams();
  const history = useHistory(); 


  const urlParams = new URLSearchParams(window.location.search);
  const transactionHashes = urlParams.get('transactionHashes');
  if (transactionHashes) {
    getTransactionResult(transactionHashes)
      .then(result => {
        if (result.status == 'success') {
          setShowSuccess(true);
          setShowError(false);
          setTransactionUrl(result.url);
          if (result.method == 'ft_transfer_call') {
            setSuccessMessage(
              "Token has successfully been minted to you account"
            );
          }
          if (result.method == 'sell_token') {
            setSuccessMessage(
              "Token has successfully been burned from your account"
            );
          }

        } else {
          setShowSuccess(false);
          setShowError(true);
          setTransactionUrl(result.url);
          setErrorMessage("Transaction Failed ");

        }
        // Do something with the result here, such as setting it to a variable
      })
      .catch(error => {
        console.error(`Error getting transaction result: ${error}`);
      });

  }

  const [appState, setAppState] = useState({
    loading: true,
    fund: {},
    account: "",
    balance: 0,
    showMessage: false,
  });
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [transactionUrl, setTransactionUrl] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [wallet, setWallet] = useState(null);
  const [contract, setContract] = useState(null);
  const [bbContract, setBBContract] = useState(null);
  const [refContract, setRefContract] = useState(null);
  const [indexTokens, setIndexTokens] = useState([]);
  const [amtIn, setAmtIn] = useState(0);
  const [minIn, setMinIn] = useState(0);
  const [indexdata, setIndexData] = useState(0);
  const [indexMessage, setIndexMessage] = useState(null);
  const [indexValue, setIndexValue] = useState(null);
  const [tokenDist, setTokenDist] = useState(null);
  const [balance, setBalance] = useState("");
  const [accountId, setAccountId] = useState("");
  const [indexbalance, setIndexBalance] = useState("");
  const [updateBalance, setUpdateBalance] = useState(false);

  const [indexDetails, setIndexDetails] = useState(null);
  const [distribution, setDistibution] = useState({});
  window.nearInitPromise = initContract().catch(console.error)

  //fetches Index information
  useEffect(() => {
    setAppState({ ...appState, loading: true });
    fetch("/indexrepository.json")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const index = data[0][capitalizeFirstLetter(chn)][id];
        setAppState({ ...appState, loading: false, fund: index });
        setIndexDetails(index);
        setIndexData({ "wrap.testnet": 0, "ref.fakes.testnet": 0, "dai.fakes.testnet": 0, })
      })
      .catch((err) => console.log(err));
  }, [id]);

  // Establish a connection to the NEAR blockchain on component mount
  useEffect(() => {
    connect(getConfig()).then((near) => setWallet(new WalletConnection(near)));
  }, []);



  // Initialize the contract object when the wallet is available
  useEffect(() => {
    if (wallet && indexDetails) {
      setContract(
        new Contract(wallet.account(), "ref-finance-101.testnet", {
          viewMethods: ["get_pools", "get_pool_total_shares", "get_return"],
          changeMethods: [],
        })
      );

      setBBContract(
        new Contract(wallet.account(), indexDetails.contractId, {
          viewMethods: [
            "ft_metadata",
            "ft_total_supply",
            "ft_balance_of",
            "ft_token_allocation",
            "min_investment",
          ],
          changeMethods: [
            "ft_mint",
            "ft_burn",
            "sell_token",
            "buy_token",
            "update_token_allocation",
            "update_input_token",
            "update_base_price",
            "bof",
          ],
        })
      );

      setRefContract(
        new Contract(wallet.account(), "usdt.fakes.testnet", {
          viewMethods: ["ft_metadata"],
          changeMethods: ["ft_transfer", "ft_transfer_call"],
        })
      );

      // We can get the account balance of a user through the wallet
      // Since this is requesting data from the blockchain, the method returns a Promise
      wallet
        .account()
        .getAccountBalance()
        .then(({ available }) => setBalance(available));

      setAccountId(wallet.account().accountId)
    }
  }, [wallet, indexDetails]);

  const isSignedIn = Boolean(wallet && wallet.isSignedIn() && contract);

  // Update the counter value when the contract is available
  // (which means that the user is signed in and the contract has been initialized)
  // Calling contract functions is similar to calling API endpoints in traditional web apps
  // The call happens asynchronously and the result is returned in a Promise
  useEffect(() => {
    if (isSignedIn && indexDetails) {
      const compDetails = [];
      for (const tokenOut of indexDetails.iCmp) {
        compDetails.push(
          contract.get_pools({
            from_index: tokenOut.pId,
            limit: 1,
          })
        );
      }
      Promise.all(compDetails).then((pools) => {
        // console.log(pools)
        setIndexTokens(pools);
      });
    }
  }, [contract, isSignedIn, indexDetails]);

  useEffect(() => {
    if (indexTokens.length > 0) {
      const tokenDistLocal = [];

      for (let token of indexTokens) {
        token = token[0];
        const id = token.token_account_ids.indexOf(indexDetails.iTin.addr);
        const tokenIn = token.token_account_ids[id];
        const tokenInLiquidity = token.amounts[id];
        const tokenOut =
          token.token_account_ids[token.token_account_ids.length - 1 - id];
        const tokenOutLiquidity = token.amounts[token.amounts.length - 1 - id];
        const tokenOutDetails = indexDetails.iCmp.find(
          (token) => token.addr == tokenOut
        );

        const outWithOneIn = tokenOutLiquidity / tokenInLiquidity;
        // const inForMinOut = Number(indexMessage.token_deposits.indexOf(indexMessage.token_list.indexOf(tokenOut)))/Number(indexMessage.amount)
        const inForMinOut = indexdata[tokenOut]
        const poolFee = token.total_fee / 1000;
        tokenDistLocal.push({
          tokenIn,
          tokenOut,
          outWithOneIn,
          inForMinOut,
          poolFee,
        });
      }
      setTokenDist(tokenDistLocal);
      let min = 0;
      for (const dist of tokenDistLocal) {
        min += dist.inForMinOut;
      }
      setMinIn(min);
    }
    
    calculateTotalInvestment(contract)
      .then((data) => {
        // console.log("msg", data);
        setIndexValue((data.amount / 1000000).toFixed(2))
        setIndexMessage(data)
      })
      .catch(error => console.error(error))
  }, [indexTokens]);

  useEffect(() => {
    if(wallet)
    fetchAccountBalance( bbContract,wallet.account().accountId).then(
      (data) => {
        setIndexBalance((data/100000000).toFixed(1))
      }
    )
  }, [bbContract]);

  useEffect(() => {
    if (indexMessage) {
      let allocation = {};
      for (let i = 0; i < 3; i++) {
        allocation[`${indexMessage.token_list[i]}`] = indexMessage.token_deposits[i] / indexMessage.token_deposits.reduce((acc, curr) => acc + parseInt(curr), 0) * 100;
      }
      let tokendistriution = [
        {
          "tokenIn": "usdt.fakes.testnet",
          "tokenOut": "wrap.testnet",
          "outWithOneIn": 16694310293.183752,
          "inForMinOut": allocation["wrap.testnet"],
          "poolFee": 0.03
        },
        {
          "tokenIn": "usdt.fakes.testnet",
          "tokenOut": "ref.fakes.testnet",
          "outWithOneIn": 12512195.8984002,
          "inForMinOut": allocation["ref.fakes.testnet"],
          "poolFee": 0.03
        },
        {
          "tokenIn": "usdt.fakes.testnet",
          "tokenOut": "dai.fakes.testnet",
          "outWithOneIn": 491022639.20773816,
          "inForMinOut": allocation["dai.fakes.testnet"],
          "poolFee": 0.03
        }
      ]
      setTokenDist(tokendistriution)
    }
  }, [indexMessage]);

  useMemo(() => {
    if (tokenDist && indexDetails) {
      let swapFee = 0;
      const split = [];
      for (const dist of tokenDist) {
        const amtInDist = Math.floor(dist.inForMinOut);
        const poolFee = dist.poolFee * amtInDist;
        swapFee += poolFee;
        const minOut = dist.outWithOneIn * (amtInDist - poolFee);
        split.push({
          tokenIn: dist.tokenIn,
          tokenOut: dist.tokenOut,
          poolFee,
          minOut,
          amtInDist,
        });
      }
      const amountIn = (minIn + swapFee) / 0.94;

      setDistibution({
        amountIn,
        swapFee,
        split,
      });

    }
  }, [tokenDist]);



  // Handle the sign in call by requesting a sign in through the NEAR Wallet
  const handleLogin = () => {
    wallet.requestSignIn({
      contractId: "ref-finance-101.testnet",
      methodNames: ["get_pools", "get_pool_total_shares", "get_deposits"],
    });
    wallet.requestSignIn(indexDetails.contractId);
  };

  const showAccountBalance = () => {
    fetchAccountBalance(appState.account).then((resp) => {
      let balance = parseInt(resp);
      let actualBalance = balance / Math.pow(10, 24);
      setAppState({
        ...appState,
        loading: false,
        balance: actualBalance.toFixed(2),
      });
      setUpdateBalance(false);
    });
  };

  const processTransaction = () => {
    setAppState({ ...appState, loading: true });
    buyToken(distribution, bbContract)
      .then((resp) => {
        setAppState({ ...appState, loading: false });
        if (resp.includes("error")) {
          setShowSuccess(false);
          setShowError(true);
          setErrorMessage(resp);
        } else {
          setShowSuccess(true);
          setShowError(false);
          setSuccessMessage(
            "Token has successfully been minted to you account"
          );
        }
        setUpdateBalance(true);
      })
      .then(showAccountBalance);
  };

  const processSell = () => {

    calculateReturn(contract, bbContract)
      .then((data) => {
        console.log(data)
      })
      .catch(error => console.error(error))
  };

  const buyIndex = () => {
    callftTransferCall(refContract, contract, indexMessage)
      .then((data) => {
        console.log("trensfer call initiated", data);
      })
      .catch(error => console.error(error))
    // fetchRefConMetadata.then((resp) => console.log(resp))
    // setIndexMessage(a);

  };

  return (
    <Container fluid className="module-container portfolio-page-container">
      <a onClick={history.goBack}>
        <h2 className="module-title">
          <IoChevronBack /> Explore
        </h2>
      </a>
      {appState.loading && appState.fund ? (
        <div className="loader-container">
          <Spinner
            className="loader"
            animation="border"
            role="status"
          ></Spinner>
        </div>
      ) : (
        <Row>
          <Col xl={7} className="information-column">
            <Row className="title-info-row">
              <Image
                src={appState.fund["icon"]}
                roundedCircle
                className="icon"
              />
              <div className="title-block">
                <h2>{appState.fund["iName"]}</h2>
                <h5>{appState.fund["iSym"]}</h5>
              </div>
            </Row>
            <Row className="general-info-row">
              <Col className="creator-info-column" xl={4}>
                <Image
                  className="creator-icon"
                  src={appState.fund["creatorIcon"]}
                  roundedCircle
                />
                <div>
                  <p className="creator-name">{appState.fund["creator"]}</p>
                  <p className="subtext"> Creator</p>
                </div>
              </Col>
            </Row>
            <Row className="information-row">
              <h4 className="title">Allocations</h4>
              <DistributionTable
                {...props}
                tokens={appState.fund["iCmp"]}
                tokenDist={tokenDist}
              />
            </Row>
            <Row className="information-row">
              <h4 className="title">Overview</h4>
              <div className="information-text">{appState.fund["iDesc"]}</div>
            </Row>
            <Row className="information-row">
              <h4 className="title">Methodology (Sample)</h4>
              <div
                className="information-text"
                dangerouslySetInnerHTML={{
                  __html: appState.fund["iMethodology"],
                }}
              ></div>
            </Row>
            <Row className="information-row">
              <div className="information-text">
                Click to view detailed (Sample){" "}
                <a
                  href={necoPdf}
                  target="_blank"
                  className="pdf-link"
                  rel="noreferrer"
                >
                  factsheet
                </a>
              </div>
            </Row>
          </Col>
          <Col xl={5} className="transaction-column">
            <div className="fixed-column">
              <Row>
                <Col className="portfolio-info-container wallet-info">
                  <ListGroup flush className="wallet-info-block">
                    <ListGroupItem>
                      <div className="transaction-input-block">
                        <h5 className="block-title">Token Value: </h5>

                        <h4 className="block-title">{indexValue} USDT</h4>
                      </div>
                    </ListGroupItem>
                    {indexbalance ? (
                      <ListGroupItem>
                        <p>Account Balance: </p>
                        <p className="info-value">{indexbalance} NECO</p>
                      </ListGroupItem>
                    ) : (
                      ""
                    )}
                  </ListGroup>
                </Col>
              </Row>
              <Row></Row>
              <ListGroup className="fund-action-list-group">
                {/* { window.walletConnection.isSignedIn() ? ( */}
                {isSignedIn ? (
                  <Fragment>
                    <ListGroup.Item
                      action
                      onClick={buyIndex}
                      className="fund-action-container accent"
                      eventKey="buy"
                    >
                      <IoHammerSharp /> Mint 1{" "}
                      {indexDetails ? indexDetails.iSym : ""}
                    </ListGroup.Item>
                    {/** If account has balance, then show burn button */}
                    <ListGroup.Item
                      action
                      onClick={processSell}
                      className="fund-action-container accent"
                      eventKey="burn"
                    >
                      <IoFlameSharp /> Burn 1{" "}
                      {indexDetails ? indexDetails.iSym : ""}
                    </ListGroup.Item>
                    <ListGroup.Item
                      action
                      onClick={logout}
                      // onClick={processFtTransfer}
                      className="fund-action-container accent"
                    >
                      <IoLogOut /> Logout
                    </ListGroup.Item>
                  </Fragment>
                ) : (
                  <ListGroup.Item
                    action
                    className="fund-action-container accent"
                    onClick={() => handleLogin()}
                  >
                    <IoWallet /> <span>Connect to NEAR Wallet</span>
                  </ListGroup.Item>
                )}
              </ListGroup>
            </div>
          </Col>
        </Row>
      )}

      <SuccessModal
        show={showSuccess}
        onHide={() => setShowSuccess(false)}
        action={() => {
          setShowSuccess(false);
          history.push('/defi/baskets/near/0')
        }}
        actionText="Close"
        msg={successMessage}
        url={transactionUrl}
      />
      <ErrorModal
        show={showError}
        onHide={() => setShowError(false)}
        msg={errorMessage}
        action={() => {
          setShowError(false);
          history.push('/defi/baskets/near/0')
        }}
        actionText="Close"
        url={transactionUrl}
      />
    </Container>
  );
};

const PortfolioContainer = (props) => {
  return <PortfolioPage {...props} />;
};

export default PortfolioContainer;
