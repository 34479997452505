import {
  connect,
  Contract,
  WalletConnection,
  utils,
  keyStores,
  providers,
} from "near-api-js";
import getConfig from "./config";
const BigNumber = require('bignumber.js');


const nearConfig = getConfig(process.env.NODE_ENV || "testnet");

// Initialize contract & set global variables
export async function initContract() {
  // Set a connection to the NEAR network
  window.near = await connect(nearConfig);

  // Initialize a Wallet Object
  window.walletConnection = new WalletConnection(window.near);
  // console.log("Connection Done")
  // Initialize a Contract Object (to interact with the contract)
  window.contract = await new Contract(
    window.walletConnection.account(), // user's account
    nearConfig.contractName, // contract's account
    {
      viewMethods: [
        "ft_metadata",
        "ft_total_supply",
        "ft_balance_of",
        "ft_token_allocation",
        "min_investment",
      ],
      changeMethods: [
        "ft_mint",
        "ft_burn",
        "sell_token",
        "buy_token",
        "update_token_allocation",
        "update_input_token",
        "update_base_price",
        "bof",
      ],
    }
  );

  window.refdexcontract = await new Contract(
    window.walletConnection.account(), // user's account
    "ref-finance-101.testnet", // contract's account
    {
      viewMethods: ["get_pools", "get_pool_total_shares", "get_return", "yget_deposits"],
      changeMethods: [],
    }
  );
}

export function logout() {
  window.walletConnection.signOut();
  // reload page
  window.location.replace(window.location.origin + window.location.pathname);
}

export function login() {
  // Allows to make calls to the contract on the user's behalf.
  // Works by creating a new access key for the user's account
  // and storing the private key in localStorage.
  window.walletConnection.requestSignIn(nearConfig.contractName);
  window.walletConnection.requestSignIn({
    contractId: "ref-finance-101.testnet",
    methodNames: ["get_pools", "get_pool_total_shares", "get_deposits"],
  });
}

export async function fetchMetadata() {
  let response = await window.contract.ft_metadata();
  return response;
}

export async function fetchMinInvestment() {
  let response = await window.contract.min_investment();
  return response;
}

export async function fetchTotalSupply() {
  let response = await window.contract.ft_total_supply();
  return response;
}

export async function fetchTokenAllocation() {
  let response = await window.contract.ft_token_allocation();
  return response;
}

export async function fetchAccountBalance(bbContract, account) {
  let response = await bbContract.ft_balance_of({ account_id: account });
  return response;
}

export async function updateBaseprice() {
  let response = await window.contract.update_base_price({
    base_price: "500000000000000000",
  });
  return response;
}

// export async function buyToken(amount, tokenList, tokenDeposit) {
export async function buyToken(distribution, bbContract) {
  let tokenInfo = {
    amount: distribution.amountIn.toString(),
    token_list: distribution.split.map((token) => token.tokenOut),
    token_deposits: distribution.split.map((token) =>
      token.amtInDist.toString()
    ),
  };
  let gas = 7200000000000000000;
  let amount = tokenInfo.amount;
  let response = await bbContract.ft_token_allocation();
  console.log(response)

  return response;

}

export async function burnToken() {
  console.log("Calling sell_token");
  let tokenInfo = {
    index_token: "1",
  };

  let gas = 300000000000000;
  let response = await window.contract.sell_token(tokenInfo, gas);

  return response;
}

export async function callBof() {
  console.log("Calling callBof");
  let tokenInfo = {
    tokens: "10000",
  };

  let response = await window.contract.bof(tokenInfo);
  return response;
}

//FT transfer function and new contract obj to communicate with ref.fakes.testnet
export async function initRefContract() {
  // Initialize a Wallet Object
  window.walletConnection = window.walletConnection
    ? window.walletConnection
    : new WalletConnection(window.near);

  // Initialize a Contract Object (to interact with the contract)
  window.refcontract = await new Contract(
    window.walletConnection.account(), // user's account
    "ref.fakes.testnet", // contract's account
    {
      viewMethods: ["ft_metadata"],
      changeMethods: ["ft_transfer", "ft_transfer_call"],
    }
  );
}

export async function callftTransfer() {
  console.log("Calling ft_transfer");
  let gas = 300000000000000;

  let info = {
    receiver_id: window.contract.contractId,
    amount: "10000000",
  };
  let amt = 1;
  let argsObj = {
    args: info,
    amount: amt,
    gas: gas,
  };

  let response = await window.refcontract.ft_transfer(argsObj);
  return response;
}

export async function fetchRefConMetadata() {
  let response = await window.refcontract.ft_metadata();
  return response;
}

export async function calculateInvestment(contract, poolId, inputToken, outputToken, expectedAmount) {
  let pooldetail = await contract.get_pools({ "from_index": poolId, "limit": 1 })
  let parseRes = pooldetail
  let x = new BigNumber(0);
  let y = new BigNumber(0);
  if (parseRes[0].token_account_ids[0] == inputToken) {
    x = new BigNumber(parseRes[0].amounts[0]);
    y = new BigNumber(parseRes[0].amounts[1]);
  } else {
    x = new BigNumber(parseRes[0].amounts[1]);
    y = new BigNumber(parseRes[0].amounts[0]);
  }
  let exchangeFee = parseRes[0].total_fee;
  const Investment = x.times(new BigNumber(expectedAmount)).dividedBy(y.minus(new BigNumber(expectedAmount)))
  let requiredInvestment = new BigNumber(Math.round(Number(Investment.toFixed()) / (1 - exchangeFee / 10000)))
  if (requiredInvestment.isLessThan(0)) {
    console.log("Not Enough Liquidity in Pool");
    return
  }
  // return res
  try {
    for (let i = 0; i < 10; i++) {
      const args = { "pool_id": poolId, "token_in": inputToken, "amount_in": requiredInvestment.toFixed(), "token_out": outputToken, "min_amount_out": 1 }
      let result = await contract.get_return(args)
      let parsedResult = new BigNumber(result)
      if (parsedResult.isGreaterThanOrEqualTo(new BigNumber(expectedAmount))) {
        return requiredInvestment.toFixed()
      } else {
        requiredInvestment = requiredInvestment.plus(new BigNumber(i * i * i * i * i))
      }
    }
  } catch (error) {
    console.log("Error received ", error.message)
    return error
  }


}

export async function calculateTotalInvestment(contract) {
  let p1 = await calculateInvestment(contract, 31, "usdt.fakes.testnet", "ref.fakes.testnet", 1000000000000000000) //1
  let p2 = await calculateInvestment(contract, 215, "usdt.fakes.testnet", "dai.fakes.testnet", 2000000000000000000) //2
  let p3 = await calculateInvestment(contract, 34, "usdt.fakes.testnet", "wrap.testnet", 500000000000000000000000) //0.5
  let sum = new BigNumber(p1).plus(new BigNumber(p2)).plus(new BigNumber(p3))
  const managerFee = 0.5
  const platformFee = 0.5
  // a * (1 - x/100) = b 

  // const result = sum.times(new BigNumber(1).minus((new BigNumber(1)).dividedBy(100)));
  // const x = new BigNumber('1');
  const a = new BigNumber('0.2');
  const b = new BigNumber('0.2');
  const c = new BigNumber('0.3');

  const denominator = new BigNumber('1')
    .minus(a.div(100))
    .minus(b.div(100))
    .minus(c.div(100));
  const result = sum.div(denominator);
  const rseult1 = result.plus(new BigNumber("300000"))
  // const result = sum.times(new BigNumber(1).plus(x.div(100)));
  // const totalInvestment=sum(1-(managerFee+platfromFee)/10)
  let msg = { "amount": rseult1.toFixed(0), "token_list": ["ref.fakes.testnet", "wrap.testnet", "dai.fakes.testnet"], "token_deposits": [(Number(p1)).toString(), (Number(p3)).toString(), (Number(p2)).toString()] }
  return msg
}

export async function calculateReturn(contract, bbContract) {
  const args1 = { "pool_id": 31, "token_in": "ref.fakes.testnet", "amount_in": "1000000000000000000", "token_out": "usdt.fakes.testnet", "min_amount_out": 1 };
  const args2 = { "pool_id": 215, "token_in": "dai.fakes.testnet", "amount_in": "2000000000000000000", "token_out": "usdt.fakes.testnet", "min_amount_out": 1 };
  const args3 = { "pool_id": 34, "token_in": "wrap.testnet", "amount_in": "500000000000000000000000", "token_out": "usdt.fakes.testnet", "min_amount_out": 1 }
  let result1 = await contract.get_return(args1)
  let result2 = await contract.get_return(args2)
  let result3 = await contract.get_return(args3)
  const total = Number(result1) + Number(result2) + Number(result3);
  let args = {
    index_token: "100000000",
    amount_to_return: total.toString()
  };
  let amt = 1;
  let gas = 300000000000000;
  let argsObj = {
    args: args,
    amount: amt,
    gas: gas,
  };
  let a = 67;

  let response = await bbContract.sell_token(argsObj);
  return response;
  // return Number(result1)+Number(result2)+Number(result3)
}

export async function getTransactionResult(txHash) {
  // Create a new Near connection
  const near = await connect({
    nodeUrl: 'https://rpc.testnet.near.org',
    deps: { keyStore: new keyStores.BrowserLocalStorageKeyStore() },
  });

  // Get the transaction data
  const provider = near.connection.provider;
  const tx = await provider.txStatus(txHash, "d_c.testnet");
  const method_name = tx.transaction.actions[0].FunctionCall.method_name;
  if (tx.status.SuccessValue) {
    if (tx.receipts_outcome.length == 24 && method_name == 'ft_transfer_call') {
      return { "status": "success", "url": `https://testnet.nearblocks.io/txns/${txHash}`, "method": `${method_name}` }
    }
    if (tx.receipts_outcome.length == 16 && method_name == 'sell_token') {
      return { "status": "success", "url": `https://testnet.nearblocks.io/txns/${txHash}`, "method": `${method_name}` }
    }
  } else {
    return { "status": "failed", "url": `https://testnet.nearblocks.io/txns/${txHash}` }
  }
  

}
export async function callftTransferCall(refContract, contract, args) {
  let info = {
    receiver_id: "necco.invex.testnet",
    amount: args.amount,
    msg: JSON.stringify(args),
  };
  let amt = 1;
  let gas = 300000000000000;
  let argsObj = {
    args: info,
    amount: amt,
    gas: gas,
  };

  let response = await refContract.ft_transfer_call(argsObj);
  return response;

}
